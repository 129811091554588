@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.SubMenu {
  flex-basis: 50%;
  cursor: pointer; }
  .SubMenu div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px; }
    .SubMenu div span {
      display: flex; }
    .SubMenu div .Add {
      fill: #0084f4;
      stroke: #0084f4;
      stroke-width: 50px; }
    .SubMenu div .Delete {
      fill: #ff647c;
      stroke: #ff647c;
      stroke-width: 50px; }
    .SubMenu div .Edit {
      fill: #00c48c; }
