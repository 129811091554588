.NavigationItem {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0; }
  .NavigationItem a {
    height: 100%;
    width: 100%;
    color: #333333;
    text-decoration: none;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    justify-content: space-between; }
    .NavigationItem a .Title {
      min-width: 90px;
      max-width: 120px;
      overflow: hidden;
      font-size: 0.9rem;
      font-weight: 300;
      color: #333333; }
    .NavigationItem a .Symbol {
      width: 22px;
      height: 22px;
      margin: 12px 0; }
      .NavigationItem a .Symbol svg {
        fill: #333333;
        stroke: #0066cc;
        stroke-width: 1px; }
    .NavigationItem a .Indicator {
      display: none; }
    .NavigationItem a:hover .Title, .NavigationItem a:active .Title {
      font-weight: 300;
      color: #1988f8; }
    .NavigationItem a:hover .Symbol svg, .NavigationItem a:active .Symbol svg {
      fill: #1988f8;
      stroke: #1988f8;
      stroke-width: 1px; }
    .NavigationItem a.active .Title {
      font-weight: bold;
      color: #0066cc; }
    .NavigationItem a.active .Symbol svg {
      fill: #0066cc;
      stroke: #0066cc;
      stroke-width: 20px; }

@media (min-width: 500px) {
  .NavigationItem {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 10px 0; }
    .NavigationItem a {
      height: 100%;
      width: 100%;
      color: #0066cc;
      text-decoration: none;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      padding-left: 12px;
      position: relative;
      justify-content: space-between; }
      .NavigationItem a .Symbol {
        margin: 12px 24px; }
      .NavigationItem a.active .Indicator {
        display: block;
        position: absolute;
        right: -1px;
        width: 2px;
        height: 100%;
        background: #0066cc;
        border-radius: 50%/3%; } }
