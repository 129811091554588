.CustomDatePicker {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  width: 100px; }

.CustomDatePickerWrapper {
  display: flex;
  flex-basis: 50%; }
