.AccountMenu {
  position: absolute;
  margin-top: 56px;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  background: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 7px 10px -5px #d6d6d6;
  z-index: 990;
  transition: transform 0.12s ease-in-out; }

.AccountMenu ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.AccountMenu li {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer; }
  .AccountMenu li a {
    text-decoration: none;
    color: #000; }
    .AccountMenu li a:hover {
      color: #1988f8; }

.AccountMenu li:last-child {
  border-bottom: none; }

@media (min-width: 500px) {
  .AccountMenu {
    width: 200px; } }
