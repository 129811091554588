@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.SubSection {
  width: 100%; }
  .SubSection .Content {
    display: flex;
    width: auto;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px 0.4px #d6d6d6;
    margin: 0;
    margin-bottom: 15px;
    transition: 0.2s box-shadow ease;
    display: flex;
    flex-flow: column;
    margin: 0 5px;
    margin-bottom: 15px;
    font-size: 0.85rem; }
    .SubSection .Content:hover {
      box-shadow: 0 0 7px 0.5px #bdbbbb; }
    .SubSection .Content .Header {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      border-bottom: 1px solid #ddd; }
      .SubSection .Content .Header .Title {
        font-size: 0.9rem; }
      .SubSection .Content .Header .Goto {
        display: flex;
        cursor: pointer; }
    .SubSection .Content .Menu {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap; }

@media (min-width: 500px) {
  .SubSection {
    flex-basis: 25%; }
    .SubSection .Content {
      margin-left: 0;
      margin-right: 15px; } }

@media (min-width: 500px) and (min-width: 500px) and (max-width: 1000px) {
  .SubSection {
    flex-basis: 50%; }
    .SubSection .Content {
      background: #fff;
      margin-right: 15px; } }
