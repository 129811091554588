.NavigationItem {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0; }

.NavigationItem a {
  height: 100%;
  width: 100%;
  color: #8F5C2C;
  text-decoration: none;
  display: flex;
  box-sizing: border-box;
  padding: 16px 10px; }

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40A4C8; }

@media (min-width: 500px) {
  .NavigationItem {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0; }
  .NavigationItem a {
    color: white;
    padding: 16px 10px; }
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    color: #333333; } }
