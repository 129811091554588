@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Product {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.CustomDatePicker {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100px; }

.CustomDatePickerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.PrintOnly {
  display: none; }

@media print {
  .PrintOnly {
    display: block; } }
