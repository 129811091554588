@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.MobileOnly {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center; }

.CloseBtn {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer; }

@media (min-width: 500px) {
  .MobileOnly {
    display: none; }
  .CloseBtn {
    display: none; }
  .SideDrawer {
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    z-index: 990;
    box-sizing: content-box; } }
