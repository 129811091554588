@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Section {
  padding: 0 15px !important; }

.Section__Grouper {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Section__Title {
  color: #fff;
  background: #ec407a;
  /* fallback for old browsers */
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }
  .Section__Title h4 {
    font-weight: 300; }

.Section__Content {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

@media (min-width: 500px) {
  .Section__Content {
    padding: 30px; } }
