@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.TextBtn {
  margin-left: 3px; }

.TextBtn:first-child {
  margin-left: 0; }

.TextBtn {
  display: inline-block; }
  .TextBtn .Btn {
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
    background: #1988f8;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 3px;
    padding: 4px 12px;
    height: 36px;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 0.8em;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; }
