@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Product {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }
