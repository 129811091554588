@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.NavigationItems {
  height: auto;
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column;
  width: 70%;
  margin: 56px 0; }

@media (min-width: 500px) {
  .NavigationItems {
    margin: 0;
    height: inherit;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    border-right: 1px solid #ddd;
    padding-top: 25px;
    transition: transform 0.3s ease-in-out;
    width: 100%; } }
