@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

@media (min-width: 800px) {
  .AddDialog {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddDialog {
    min-width: 350px; } }
