@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.SubContent {
  display: flex;
  flex-direction: column;
  flex-basis: 100%; }

.Header {
  margin: -30px -30px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex; }

.Actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -30px -30px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  justify-content: flex-end; }

@media (min-width: 800px) {
  .Content {
    flex-direction: row; }
  .SubContent {
    flex-basis: 40%; } }
