.NavigationItems {
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center; }

@media (max-width: 499px) {
  .DesktopOnly {
    display: none; } }
