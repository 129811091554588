@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Section {
  display: flex;
  width: auto;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 3px 0.4px #d6d6d6;
  margin: 0;
  margin-bottom: 15px;
  transition: 0.2s box-shadow ease; }
  .Section:hover {
    box-shadow: 0 0 7px 0.5px #bdbbbb; }

.SubSections {
  display: flex;
  flex-wrap: wrap; }

.Dashboard {
  padding: 15px;
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.SubSectionsTitle {
  display: inline-block;
  margin-bottom: 15px;
  background: #0336ff;
  padding: 10px 20px;
  border-radius: 20%/47.5%; }
  .SubSectionsTitle h4 {
    color: #fff;
    font-weight: 700;
    text-transform: capitalize; }

@media (min-width: 500px) {
  .SubSections > div {
    max-width: 400px; }
  .SubSections > div:last-child > div {
    margin-right: 0; } }

@media (min-width: 500px) and (min-width: 500px) and (max-width: 1000px) {
  .SubSections > div {
    max-width: 50%;
    flex-basis: 50%; }
    .SubSections > div > div {
      background: #fff;
      margin-right: 15px; }
  .SubSections > div:nth-child(even) > div {
    margin-right: 0; } }
