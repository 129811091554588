@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Toolbar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 0 12px;
  z-index: 1000; }

.GoToTop {
  background: #0336ff;
  position: fixed;
  font-size: 0.8em;
  z-index: 100;
  border-radius: 50%;
  height: 3.5em;
  width: 3.5em;
  bottom: 1em;
  right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms opacity ease; }

.Toolbar nav {
  height: 100%; }

.Logo {
  /* centering specific flex item */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* end */
  text-align: center;
  color: #333333;
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold; }
