.Auth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #fff; }

.Form {
  display: flex;
  height: auto;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  margin-top: -5em; }
  .Form .Title {
    margin: 0 40px 15px; }

.InputWrapper {
  position: relative;
  background: #fff;
  border-radius: 5px;
  margin: 0 40px 15px;
  height: 36px;
  width: auto;
  border: 1px solid #d6d6d6;
  transition: box-shadow 150ms linear; }
  .InputWrapper.active {
    border: 1px solid #d6d6d6;
    box-shadow: 0 0 10px 0.05px #d6d6d6; }
  .InputWrapper label {
    position: absolute;
    font-size: 0.8em;
    top: 0;
    left: 0;
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    color: #d0c9d6; }
  .InputWrapper .Input {
    background: none;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 4px 12px;
    height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 0.8em;
    flex: 1 0 0; }
    .InputWrapper .Input.InputPassword {
      width: calc(100% - 22px); }

.InputIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  padding: 6px;
  background: #fff;
  height: 100%;
  border-radius: 0 4px 4px 0; }

.FormButton {
  box-shadow: 0 0 10px 4px #d6d6d6;
  background: #1988f8;
  border-radius: 5px;
  padding: 4px 12px;
  margin: 0 40px 15px;
  height: 36px;
  width: auto;
  transition: 300ms background-color ease;
  cursor: pointer; }
  .FormButton:hover {
    background: #0061ff; }
  .FormButton button {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: #fff;
    font-size: 0.8em;
    font-weight: 700;
    width: 100%;
    height: 100%; }
