.Header {
  padding: 4px 10px;
  margin: 5px 0;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  background: #1988f8;
  font-weight: bold;
  justify-content: space-between; }

.Content {
  padding: 0 0 0 15px;
  font-size: 0.8rem;
  overflow: hidden;
  max-height: 1000vh;
  transition: max-height 1s ease-in; }

.Hide .Content {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  transition-delay: -0.4s; }
