@import url(https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i);

@media (max-width: 499px) {
  .SideDrawer__DesktopOnly__1l0sR {
    display: none !important; } }

.SideDrawer__MobileOnly__feyIU {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center; }

.SideDrawer__CloseBtn__1Azd3 {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer; }

@media (min-width: 500px) {
  .SideDrawer__MobileOnly__feyIU {
    display: none; }
  .SideDrawer__CloseBtn__1Azd3 {
    display: none; }
  .SideDrawer__SideDrawer__3Jf1F {
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    z-index: 990;
    box-sizing: content-box; } }

.Link__NavigationItem__2En7C {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0; }

.Link__NavigationItem__2En7C a {
  height: 100%;
  width: 100%;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  justify-content: space-between; }

.Link__NavigationItem__2En7C a .Link__Title__uPbNS {
  min-width: 90px;
  max-width: 120px;
  overflow: hidden;
  font-size: 0.9rem;
  font-weight: 300;
  color: #333333; }

.Link__NavigationItem__2En7C a .Link__Symbol__3fjwk {
  width: 22px;
  height: 22px;
  margin: 12px 0; }

.Link__NavigationItem__2En7C a .Link__Symbol__3fjwk svg {
  fill: #333333;
  stroke: #0066cc;
  stroke-width: 1px; }

.Link__NavigationItem__2En7C a .Link__Indicator__bh4xB {
  display: none; }

.Link__NavigationItem__2En7C a:hover .Link__Title__uPbNS, .Link__NavigationItem__2En7C a:active .Link__Title__uPbNS {
  font-weight: 300;
  color: #1988f8; }

.Link__NavigationItem__2En7C a:hover .Link__Symbol__3fjwk svg, .Link__NavigationItem__2En7C a:active .Link__Symbol__3fjwk svg {
  fill: #1988f8;
  stroke: #1988f8;
  stroke-width: 1px; }

.Link__NavigationItem__2En7C a.Link__active__291IU .Link__Title__uPbNS {
  font-weight: bold;
  color: #0066cc; }

.Link__NavigationItem__2En7C a.Link__active__291IU .Link__Symbol__3fjwk svg {
  fill: #0066cc;
  stroke: #0066cc;
  stroke-width: 20px; }

@media (min-width: 500px) {
  .Link__NavigationItem__2En7C {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 10px 0; }
  .Link__NavigationItem__2En7C a {
    height: 100%;
    width: 100%;
    color: #0066cc;
    text-decoration: none;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    padding-left: 12px;
    position: relative;
    justify-content: space-between; }
  .Link__NavigationItem__2En7C a .Link__Symbol__3fjwk {
    margin: 12px 24px; }
  .Link__NavigationItem__2En7C a.Link__active__291IU .Link__Indicator__bh4xB {
    display: block;
    position: absolute;
    right: -1px;
    width: 2px;
    height: 100%;
    background: #0066cc;
    border-radius: 50%/3%; } }

@media (max-width: 499px) {
  .Links__DesktopOnly__3X47H {
    display: none !important; } }

.Links__NavigationItems__1ucEt {
  height: auto;
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column;
  width: 70%;
  margin: 56px 0;
  position: relative; }

@media (min-width: 500px) {
  .Links__NavigationItems__1ucEt {
    margin: 0;
    height: inherit;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    border-right: 1px solid #ddd;
    padding-top: calc(56px + 25px);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0; } }

@media (max-width: 499px) {
  .MobileLinks__DesktopOnly__1641a {
    display: none !important; } }

.MobileLinks__NavigationItems__3YY0X {
  height: auto;
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column;
  width: 70%;
  margin: 56px 0; }

@media (min-width: 500px) {
  .MobileLinks__NavigationItems__3YY0X {
    margin: 0;
    height: inherit;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    border-right: 1px solid #ddd;
    padding-top: 25px;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    width: 100%; } }

@media (max-width: 499px) {
  .Toolbar__DesktopOnly__lT_N4 {
    display: none !important; } }

.Toolbar__Toolbar__n8_Hi {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 0 12px;
  z-index: 1000; }

.Toolbar__GoToTop__3vEQN {
  background: #0336ff;
  position: fixed;
  font-size: 0.8em;
  z-index: 100;
  border-radius: 50%;
  height: 3.5em;
  width: 3.5em;
  bottom: 1em;
  right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 200ms opacity ease;
  transition: 200ms opacity ease; }

.Toolbar__Toolbar__n8_Hi nav {
  height: 100%; }

.Toolbar__Logo__1ZBi9 {
  /* centering specific flex item */
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* end */
  text-align: center;
  color: #333333;
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold; }

.DrawerToggle__DrawerToggle__2u-7t {
  width: 22px;
  height: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1px 0;
  margin: 0 12px; }

.DrawerToggle__DrawerToggle__2u-7t div {
  width: 100%;
  height: 2px;
  background-color: #c9c9c9; }

.NavigationItem__NavigationItem__35Xo7 {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0; }

.NavigationItem__NavigationItem__35Xo7 a {
  height: 100%;
  width: 100%;
  color: #8F5C2C;
  text-decoration: none;
  display: flex;
  box-sizing: border-box;
  padding: 16px 10px; }

.NavigationItem__NavigationItem__35Xo7 a:hover,
.NavigationItem__NavigationItem__35Xo7 a:active,
.NavigationItem__NavigationItem__35Xo7 a.NavigationItem__active__TPACV {
  color: #40A4C8; }

@media (min-width: 500px) {
  .NavigationItem__NavigationItem__35Xo7 {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0; }
  .NavigationItem__NavigationItem__35Xo7 a {
    color: white;
    padding: 16px 10px; }
  .NavigationItem__NavigationItem__35Xo7 a:hover,
  .NavigationItem__NavigationItem__35Xo7 a:active,
  .NavigationItem__NavigationItem__35Xo7 a.NavigationItem__active__TPACV {
    color: #333333; } }

.NavigationItems__NavigationItems__1GzYE {
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center; }

@media (max-width: 499px) {
  .NavigationItems__DesktopOnly__anwI3 {
    display: none; } }

@media (max-width: 499px) {
  .Account__DesktopOnly__7Fhcu {
    display: none !important; } }

.Account__NavigationItem__2npbQ {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer; }

.Account__Arrow__QiuPe {
  height: 22px;
  width: 22px; }

.Account__Username__3fzKv {
  font-weight: 300;
  font-size: 0.9rem; }

.Account__NavigationItem__2npbQ a {
  height: 100%;
  width: 100%;
  color: #8f5c2c;
  text-decoration: none;
  display: flex;
  box-sizing: border-box;
  padding: 16px 10px; }

.Account__NavigationItem__2npbQ a:hover,
.Account__NavigationItem__2npbQ a:active,
.Account__NavigationItem__2npbQ a.Account__active__1__ej {
  color: #40a4c8; }

@media (min-width: 500px) {
  .Account__NavigationItem__2npbQ {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0; }
  .Account__NavigationItem__2npbQ a {
    color: white;
    padding: 16px 10px; }
  .Account__NavigationItem__2npbQ a:hover,
  .Account__NavigationItem__2npbQ a:active,
  .Account__NavigationItem__2npbQ a.Account__active__1__ej {
    color: #333333; } }

.MenuList__AccountMenu__1lcBY {
  position: absolute;
  margin-top: 56px;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  background: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 7px 10px -5px #d6d6d6;
  z-index: 990;
  -webkit-transition: -webkit-transform 0.12s ease-in-out;
  transition: -webkit-transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out, -webkit-transform 0.12s ease-in-out; }

.MenuList__AccountMenu__1lcBY ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.MenuList__AccountMenu__1lcBY li {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer; }

.MenuList__AccountMenu__1lcBY li a {
  text-decoration: none;
  color: #000; }

.MenuList__AccountMenu__1lcBY li a:hover {
  color: #1988f8; }

.MenuList__AccountMenu__1lcBY li:last-child {
  border-bottom: none; }

@media (min-width: 500px) {
  .MenuList__AccountMenu__1lcBY {
    width: 200px; } }

@media (max-width: 499px) {
  .Layout__DesktopOnly__1HDXd {
    display: none !important; } }

.Layout__Content__1kEJJ {
  height: inherit;
  min-height: inherit; }

@media (min-width: 500px) {
  .Layout__ContentWithToolbar__2e0sq {
    margin-left: calc(22px + 24px * 2); } }

@media (max-width: 499px) {
  .Dashboard__DesktopOnly__1Wdqa {
    display: none !important; } }

.Dashboard__Section__1nW5w {
  display: flex;
  width: auto;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 3px 0.4px #d6d6d6;
  margin: 0;
  margin-bottom: 15px;
  -webkit-transition: 0.2s box-shadow ease;
  transition: 0.2s box-shadow ease; }

.Dashboard__Section__1nW5w:hover {
  box-shadow: 0 0 7px 0.5px #bdbbbb; }

.Dashboard__SubSections__3YgaS {
  display: flex;
  flex-wrap: wrap; }

.Dashboard__Dashboard__3upUG {
  padding: 15px;
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.Dashboard__SubSectionsTitle__CwuxW {
  display: inline-block;
  margin-bottom: 15px;
  background: #0336ff;
  padding: 10px 20px;
  border-radius: 20%/47.5%; }

.Dashboard__SubSectionsTitle__CwuxW h4 {
  color: #fff;
  font-weight: 700;
  text-transform: capitalize; }

@media (min-width: 500px) {
  .Dashboard__SubSections__3YgaS > div {
    max-width: 400px; }
  .Dashboard__SubSections__3YgaS > div:last-child > div {
    margin-right: 0; } }

@media (min-width: 500px) and (min-width: 500px) and (max-width: 1000px) {
  .Dashboard__SubSections__3YgaS > div {
    max-width: 50%;
    flex-basis: 50%; }
  .Dashboard__SubSections__3YgaS > div > div {
    background: #fff;
    margin-right: 15px; }
  .Dashboard__SubSections__3YgaS > div:nth-child(even) > div {
    margin-right: 0; } }

@media (max-width: 499px) {
  .SubMenu__DesktopOnly__36X14 {
    display: none !important; } }

.SubMenu__SubMenu__2ypUt {
  flex-basis: 50%;
  cursor: pointer; }

.SubMenu__SubMenu__2ypUt div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px; }

.SubMenu__SubMenu__2ypUt div span {
  display: flex; }

.SubMenu__SubMenu__2ypUt div .SubMenu__Add__2J0ZK {
  fill: #0084f4;
  stroke: #0084f4;
  stroke-width: 50px; }

.SubMenu__SubMenu__2ypUt div .SubMenu__Delete__OaTTX {
  fill: #ff647c;
  stroke: #ff647c;
  stroke-width: 50px; }

.SubMenu__SubMenu__2ypUt div .SubMenu__Edit__3t07b {
  fill: #00c48c; }

@media (max-width: 499px) {
  .CrudSubSection__DesktopOnly__32iJc {
    display: none !important; } }

.CrudSubSection__SubSection__DC1g8 {
  width: 100%; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV {
  display: flex;
  width: auto;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 3px 0.4px #d6d6d6;
  margin: 0;
  margin-bottom: 15px;
  -webkit-transition: 0.2s box-shadow ease;
  transition: 0.2s box-shadow ease;
  display: flex;
  flex-flow: column;
  margin: 0 5px;
  margin-bottom: 15px;
  font-size: 0.85rem; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV:hover {
  box-shadow: 0 0 7px 0.5px #bdbbbb; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV .CrudSubSection__Header__1SUeL {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #ddd; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV .CrudSubSection__Header__1SUeL .CrudSubSection__Title__2_8h_ {
  font-size: 0.9rem; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV .CrudSubSection__Header__1SUeL .CrudSubSection__Goto__YbjmC {
  display: flex;
  cursor: pointer; }

.CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV .CrudSubSection__Menu__2k7ll {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap; }

@media (min-width: 500px) {
  .CrudSubSection__SubSection__DC1g8 {
    flex-basis: 25%; }
  .CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV {
    margin-left: 0;
    margin-right: 15px; } }

@media (min-width: 500px) and (min-width: 500px) and (max-width: 1000px) {
  .CrudSubSection__SubSection__DC1g8 {
    flex-basis: 50%; }
  .CrudSubSection__SubSection__DC1g8 .CrudSubSection__Content__1FTRV {
    background: #fff;
    margin-right: 15px; } }

@media (max-width: 499px) {
  .Product__DesktopOnly__2h_P5 {
    display: none !important; } }

.Product__Product__3_yWS {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

@media (max-width: 499px) {
  .TextBtn__DesktopOnly__-4khU {
    display: none !important; } }

.TextBtn__TextBtn__3Aqtf {
  margin-left: 3px; }

.TextBtn__TextBtn__3Aqtf:first-child {
  margin-left: 0; }

.TextBtn__TextBtn__3Aqtf {
  display: inline-block; }

.TextBtn__TextBtn__3Aqtf .TextBtn__Btn__1FUzP {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  background: #1988f8;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 499px) {
  .Section__DesktopOnly__d1Xcz {
    display: none !important; } }

.Section__Section__3z7Ra {
  padding: 0 15px !important; }

.Section__Section__Grouper__37zyf {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Section__Section__Title__oj7GT {
  color: #fff;
  background: #ec407a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(30deg, #ec407a, #d81b60);
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }

.Section__Section__Title__oj7GT h4 {
  font-weight: 300; }

.Section__Section__Content__3TN3q {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

@media (min-width: 500px) {
  .Section__Section__Content__3TN3q {
    padding: 30px; } }

@media (max-width: 499px) {
  .Form__DesktopOnly__1_ymY {
    display: none !important; } }

.Form__Form__1MGBh {
  margin: 0 auto;
  font-size: 0.95em; }

@media (min-width: 500px) {
  .Form__Form__1MGBh {
    max-width: 560px; } }

@media (max-width: 499px) {
  .Input__DesktopOnly__2GHRE {
    display: none !important; } }

.Input__InputNoMarginLeftRight__3M5lH {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.Input__Input--medium__25u2c {
  height: 24px !important;
  margin: 0 0 25px !important; }

.Input__Input__3Rdyg {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  align-items: flex-start;
  justify-content: space-between; }

.Input__Input__3Rdyg .Input__Input__Btn__1LuCa {
  cursor: pointer; }

.Input__Input__3Rdyg .Input__Input__Field__18-Tb, .Input__Input__3Rdyg .Input__Input__TextArea__Ja2sQ {
  height: 36px;
  background: #fff;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  width: 100%;
  flex-basis: 100%;
  text-overflow: ellipsis;
  font-size: 0.8rem; }

.Input__Input__3Rdyg .Input__Input__Field__18-Tb:disabled, .Input__Input__3Rdyg .Input__Input__TextArea__Ja2sQ:disabled {
  background: #f2f2f2;
  border-color: #f2f2f2;
  color: #999999; }

.Input__Input__3Rdyg .Input__Input__TextArea__Ja2sQ {
  padding: 4px 5px;
  height: 100%; }

.Input__Input__3Rdyg .Input__Input__FieldWithBtn__2BnHZ {
  display: flex;
  width: 100%;
  height: 100%; }

.Input__Input__3Rdyg .Input__Input__FieldWithBtn__2BnHZ .Input__Input__3Rdyg {
  flex: 1 1; }

.Input__Input__3Rdyg .Input__Input__BigBtn__11F4s {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  background: #1988f8;
  color: #fff;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center; }

.Input__Input__3Rdyg .Input__Input__SmallBtn__3QqkE {
  background-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
  -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 3px;
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.Input__Input__3Rdyg .Input__Input__SmallBtn__3QqkE svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 25; }

@media (min-width: 500px) {
  .Input__Input--medium__25u2c {
    height: 24px !important;
    margin-bottom: 7.5px !important; }
  .Input__Input__3Rdyg {
    align-items: center;
    display: flex;
    margin: 0 40px 5px;
    flex-direction: row;
    height: 36px; }
  .Input__Input__3Rdyg .Input__Input__Field__18-Tb, .Input__Input__3Rdyg .Input__Input__TextArea__Ja2sQ {
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    text-overflow: ellipsis;
    font-size: 0.8rem; }
  .Input__Input__3Rdyg .Input__Input__Field__18-Tb:disabled, .Input__Input__3Rdyg .Input__Input__TextArea__Ja2sQ:disabled {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #999999; }
  .Input__Input__3Rdyg .Input__Input__FieldWithBtn__2BnHZ {
    display: flex;
    flex-basis: 50%;
    height: 100%;
    justify-content: flex-end; }
  .Input__Input__3Rdyg .Input__Input__FieldWithBtn__2BnHZ .Input__Input__Field__18-Tb, .Input__Input__3Rdyg .Input__Input__FieldWithBtn__2BnHZ .Input__Input__TextArea__Ja2sQ {
    flex: 1 1; }
  .Input__Input__3Rdyg .Input__Input__BigBtn__11F4s {
    flex-basis: 50%; } }

@media (max-width: 499px) {
  .AddDialog__DesktopOnly__1KhXU {
    display: none !important; } }

@media (min-width: 800px) {
  .AddDialog__AddDialog__1WZ7D {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddDialog__AddDialog__1WZ7D {
    min-width: 350px; } }

@media (max-width: 499px) {
  .AddCategoryDialog__DesktopOnly__2FY8f {
    display: none !important; } }

.AddCategoryDialog__ViewDialog__rwwcz {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .AddCategoryDialog__ViewDialog__rwwcz {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddCategoryDialog__ViewDialog__rwwcz {
    min-width: 350px; } }

@media (max-width: 499px) {
  .DialogForm__DesktopOnly__haL_q {
    display: none !important; } }

.DialogForm__Form__17bvN {
  margin: 0 auto;
  font-size: 0.95em; }

@media (max-width: 499px) {
  .DialogForm__DesktopOnly__pLycW {
    display: none !important; } }

.DialogForm__Form__dgSMj {
  margin: 0 auto;
  font-size: 0.95em; }

@media (max-width: 499px) {
  .ViewDialog__DesktopOnly__2-1gm {
    display: none !important; } }

@media (min-width: 800px) {
  .ViewDialog__ViewDialog__1lCAN {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .ViewDialog__ViewDialog__1lCAN {
    min-width: 350px; } }

.Auth__Auth__14A2b {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #fff; }

.Auth__Form__1ua1G {
  display: flex;
  height: auto;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  margin-top: -5em; }

.Auth__Form__1ua1G .Auth__Title__3OktC {
  margin: 0 40px 15px; }

.Auth__InputWrapper__3zQ88 {
  position: relative;
  background: #fff;
  border-radius: 5px;
  margin: 0 40px 15px;
  height: 36px;
  width: auto;
  border: 1px solid #d6d6d6;
  -webkit-transition: box-shadow 150ms linear;
  transition: box-shadow 150ms linear; }

.Auth__InputWrapper__3zQ88.Auth__active__AHaTa {
  border: 1px solid #d6d6d6;
  box-shadow: 0 0 10px 0.05px #d6d6d6; }

.Auth__InputWrapper__3zQ88 label {
  position: absolute;
  font-size: 0.8em;
  top: 0;
  left: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #d0c9d6; }

.Auth__InputWrapper__3zQ88 .Auth__Input__DVS2E {
  background: none;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 4px 12px;
  height: 100%;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.8em;
  flex: 1 0; }

.Auth__InputWrapper__3zQ88 .Auth__Input__DVS2E.Auth__InputPassword__Bl7UZ {
  width: calc(100% - 22px); }

.Auth__InputIcon__2J658 {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  padding: 6px;
  background: #fff;
  height: 100%;
  border-radius: 0 4px 4px 0; }

.Auth__FormButton__1jn8z {
  box-shadow: 0 0 10px 4px #d6d6d6;
  background: #1988f8;
  border-radius: 5px;
  padding: 4px 12px;
  margin: 0 40px 15px;
  height: 36px;
  width: auto;
  -webkit-transition: 300ms background-color ease;
  transition: 300ms background-color ease;
  cursor: pointer; }

.Auth__FormButton__1jn8z:hover {
  background: #0061ff; }

.Auth__FormButton__1jn8z button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  width: 100%;
  height: 100%; }

@media (max-width: 499px) {
  .Settings__DesktopOnly__1cIRm {
    display: none !important; } }

.Settings__Settings__2-nY1 {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.Settings__Settings__2-nY1 .Settings__Section__1M3X_ {
  padding: 0 15px !important; }

.Settings__Settings__2-nY1 .Settings__Wrapper__2h46q {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Settings__Settings__2-nY1 .Settings__Title__y834y {
  color: #fff;
  background: -webkit-linear-gradient(30deg, #4266b7, #2c24aa);
  background: linear-gradient(60deg, #4266b7, #2c24aa);
  box-shadow: 0 12px 20px -10px rgba(54, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(39, 66, 176, 0.2);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }

.Settings__Settings__2-nY1 .Settings__Title__y834y h4 {
  font-weight: 300; }

.Settings__Settings__2-nY1 .Settings__Content__AUrKE {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

.Settings__Settings__2-nY1 .Settings__Form__1DlWy {
  margin: 0 auto;
  font-size: 0.95em; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: flex-start;
  justify-content: space-between; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__Input__2oDyf {
  height: 36px;
  background: #fff;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  width: 100%;
  flex-basis: 100%;
  text-overflow: ellipsis;
  font-size: 0.8em; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__Input__2oDyf:disabled {
  background: #f2f2f2; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputWithBtn__E4HJp {
  display: flex;
  width: 100%;
  height: 100%; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputWithBtn__E4HJp .Settings__Input__2oDyf {
  flex: 1 1; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputBtn__GGU8v {
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  background: #1988f8;
  color: #fff;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__EditBtn__1frUI {
  background-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
  -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 3px;
  display: flex;
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__EditBtn__1frUI svg {
  fill: #fff; }

@media (min-width: 500px) {
  .Settings__Settings__2-nY1 .Settings__Form__1DlWy {
    max-width: 560px; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 {
    align-items: center;
    display: flex;
    margin: 0 40px 15px;
    flex-direction: row;
    height: 36px; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__Input__2oDyf {
    height: 100%;
    width: auto;
    flex-basis: 50%;
    text-overflow: ellipsis;
    font-size: 0.8em; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__Input__2oDyf:disabled {
    background: #f2f2f2; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputWithBtn__E4HJp {
    display: flex;
    flex-basis: 50%;
    height: 100%; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputWithBtn__E4HJp .Settings__Input__2oDyf {
    flex: 1 1; }
  .Settings__Settings__2-nY1 .Settings__InputWrapper__1r_Z4 .Settings__InputBtn__GGU8v {
    flex-basis: 50%; }
  .Settings__Settings__2-nY1 .Settings__Content__AUrKE {
    padding: 30px; } }

@media (max-width: 499px) {
  .Section__DesktopOnly__xiVin {
    display: none !important; } }

.Section__Section__3RICz {
  padding: 0 15px !important; }

.Section__Section__Grouper__31G4f {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Section__Section__Title__8eGAc {
  color: #fff;
  background: #ec407a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(30deg, #ec407a, #d81b60);
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }

.Section__Section__Title__8eGAc h4 {
  font-weight: 300; }

.Section__Section__Content__1-pO8 {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

@media (min-width: 500px) {
  .Section__Section__Content__1-pO8 {
    padding: 30px; } }

@media (max-width: 499px) {
  .ViewDialog__DesktopOnly__KINZq {
    display: none !important; } }

.ViewDialog__ViewDialog__3GKeS {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .ViewDialog__ViewDialog__3GKeS {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .ViewDialog__ViewDialog__3GKeS {
    min-width: 350px; } }

@media (max-width: 499px) {
  .AddDialog__DesktopOnly__1-urG {
    display: none !important; } }

.AddDialog__ViewDialog__3Xa7P {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .AddDialog__ViewDialog__3Xa7P {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddDialog__ViewDialog__3Xa7P {
    min-width: 350px; } }

@media (max-width: 499px) {
  .ChangePasswordDialog__DesktopOnly__1g40k {
    display: none !important; } }

.ChangePasswordDialog__ViewDialog__2MIyy {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .ChangePasswordDialog__ViewDialog__2MIyy {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .ChangePasswordDialog__ViewDialog__2MIyy {
    min-width: 350px; } }

@media (max-width: 499px) {
  .Vendor__DesktopOnly__3MGY7 {
    display: none !important; } }

.Vendor__Product__2ng93 {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

@media (max-width: 499px) {
  .Section__DesktopOnly__KKL7R {
    display: none !important; } }

.Section__Section__1vapc {
  padding: 0 15px !important; }

.Section__Section__Grouper__7omBG {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Section__Section__Title__2dFCk {
  color: #fff;
  background: #ec407a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(30deg, #ec407a, #d81b60);
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }

.Section__Section__Title__2dFCk h4 {
  font-weight: 300; }

.Section__Section__Content__2ZGDx {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

@media (min-width: 500px) {
  .Section__Section__Content__2ZGDx {
    padding: 30px; } }

@media (max-width: 499px) {
  .AddDialog__DesktopOnly__3hFMb {
    display: none !important; } }

@media (min-width: 800px) {
  .AddDialog__AddDialog__1V7oh {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddDialog__AddDialog__1V7oh {
    min-width: 350px; } }

@media (max-width: 499px) {
  .AddCategoryDialog__DesktopOnly__2l43G {
    display: none !important; } }

.AddCategoryDialog__ViewDialog__I1MTp {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .AddCategoryDialog__ViewDialog__I1MTp {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddCategoryDialog__ViewDialog__I1MTp {
    min-width: 350px; } }

@media (max-width: 499px) {
  .DialogForm__DesktopOnly__1e0ld {
    display: none !important; } }

.DialogForm__Form__2K8LH {
  margin: 0 auto;
  font-size: 0.95em; }

@media (max-width: 499px) {
  .InputWithChild__DesktopOnly__2f1Wz {
    display: none !important; } }

.InputWithChild__InputWithChild__3Pklg ul {
  list-style-type: none;
  font-size: 0.8rem; }

.InputWithChild__InputWithChild__3Pklg ul label {
  padding: 0 0 0 30px; }

.InputWithChild__InputWithChild__3Pklg ul li {
  margin: 0 0 25px; }

.InputWithChild__Header__CSJjP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 45px; }

.InputWithChild__Btn__11PlH {
  cursor: pointer;
  background: red;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.InputWithChild__Btn__11PlH.InputWithChild__Add__2z498 {
  background: #0084f4; }

.InputWithChild__Btn__11PlH.InputWithChild__Remove__1rWRH {
  background: #ff647c; }

.InputWithChild__Btn__11PlH svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 25; }

@media (min-width: 500px) {
  .InputWithChild__InputWithChild__3Pklg ul li {
    margin: 0; }
  .InputWithChild__Header__CSJjP {
    margin-top: 0; } }

@media (max-width: 499px) {
  .LabelWithBtn__DesktopOnly__UPEID {
    display: none !important; } }

.LabelWithBtn__InputWithChild__2fnOx ul {
  padding: 0 0 0 15px;
  list-style-type: none;
  font-size: 0.8rem; }

.LabelWithBtn__InputWithChild__2fnOx ul li {
  margin: 0 0 25px; }

.LabelWithBtn__Header__1b0VC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 45px; }

.LabelWithBtn__Btn__168O7 {
  cursor: pointer;
  background: red;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.LabelWithBtn__Btn__168O7.LabelWithBtn__Add__10t3l {
  background: #0084f4; }

.LabelWithBtn__Btn__168O7.LabelWithBtn__Remove__2wD8S {
  background: #ff647c; }

.LabelWithBtn__Btn__168O7 svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 25; }

@media (min-width: 500px) {
  .LabelWithBtn__InputWithChild__2fnOx ul {
    padding: 0 0 0 15px; }
  .LabelWithBtn__InputWithChild__2fnOx ul li {
    margin: 0; }
  .LabelWithBtn__Header__1b0VC {
    margin-top: 0; } }

.MinimizeableInput__Header__94y-i {
  padding: 4px 10px;
  margin: 5px 0;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  background: #1988f8;
  font-weight: bold;
  justify-content: space-between; }

.MinimizeableInput__Content__3WwtF {
  padding: 0 0 0 15px;
  font-size: 0.8rem;
  overflow: hidden;
  max-height: 1000vh;
  -webkit-transition: max-height 1s ease-in;
  transition: max-height 1s ease-in; }

.MinimizeableInput__Hide__CkS1S .MinimizeableInput__Content__3WwtF {
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
  -webkit-transition-delay: -0.4s;
          transition-delay: -0.4s; }

@media (max-width: 499px) {
  .Content__DesktopOnly__2n6nG {
    display: none !important; } }

.Content__Content__1iHFX {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.Content__SubContent__3gqSz {
  display: flex;
  flex-direction: column;
  flex-basis: 100%; }

.Content__Header__10vxQ {
  margin: -30px -30px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex; }

.Content__Actions__355GE {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -30px -30px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  justify-content: flex-end; }

@media (min-width: 800px) {
  .Content__Content__1iHFX {
    flex-direction: row; }
  .Content__SubContent__3gqSz {
    flex-basis: 40%; } }

@media (max-width: 499px) {
  .ViewDialog__DesktopOnly__1orly {
    display: none !important; } }

@media (min-width: 800px) {
  .ViewDialog__ViewDialog__75yxi {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .ViewDialog__ViewDialog__75yxi {
    min-width: 350px; } }


@media (max-width: 499px) {
  .Report__DesktopOnly__9Ie_y {
    display: none !important; } }

.Report__Product__9HP_X {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.Report__CustomDatePicker__1NNEb {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100px; }

.Report__CustomDatePickerWrapper__1QQS_ {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Report__PrintOnly__2G4Dw {
  display: none; }

@media print {
  .Report__PrintOnly__2G4Dw {
    display: block; } }

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */ }

.ReportFormPrint__ReportFormPrintWrapper__3Oyyn {
  page-break-inside: avoid; }

.ReportFormPrint__ReportFormPrint__2sPHP {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 4.5px 20.5px;
  font-size: 0.9em; }

.ReportFormPrint__ReportHeaderLayout__2ioUY {
  padding: 4.5px 20.5px;
  margin: 0 -20.5px;
  border-bottom: 1px solid black; }

.ReportFormPrint__WrapperAfterHeader__106IX {
  margin: 16px 0; }

.ReportFormPrint__DetailLayout__28Z0B {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.ReportFormPrint__Report__FieldWrapper__3pDTe,
.ReportFormPrint__Customer__FieldWrapper__FsIJI {
  padding: 0 0 0 15px;
  font-size: 0.9rem; }

.ReportFormPrint__Report__FieldGroup__3KBLi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.5px; }

.ReportFormPrint__Customer__FieldGroup__27wRz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 0.8rem;
  margin-bottom: 7.5px; }

.ReportFormPrint__ReportDetailLayout__i_uyz, .ReportFormPrint__ProductLayout__bYMz6,
.ReportFormPrint__DescriptionLayout__3gpmG,
.ReportFormPrint__CustomerDetailLayout__3LNAr {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  font-weight: 500; }

.ReportFormPrint__ProductLayout__bYMz6,
.ReportFormPrint__DescriptionLayout__3gpmG {
  flex-basis: 100%; }

.ReportFormPrint__ProductWrapper__-_nMk {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #a7a2a2;
  border-radius: 3px;
  padding: 10px 14px;
  margin-bottom: 7.5px; }

.ReportFormPrint__Product__1Aluc {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.ReportFormPrint__ProductWrapper__-_nMk:last-child {
  margin-bottom: 20.5px; }

.ReportFormPrint__Product__OriOrChange__mYszf {
  flex-basis: 45%; }

.ReportFormPrint__Product__FieldGroup__aXVxs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 7.5px; }

.ReportFormPrint__DescriptionLayout__3gpmG {
  margin: 0 -20.5px;
  padding: 0 20.5px;
  padding-top: 5px;
  border-top: 1px solid black; }

.ReportFormPrint__DescriptionTextArea__1n3AM {
  width: 100%;
  height: auto;
  min-height: 35px;
  flex-basis: 50%;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  background: #fff;
  border: 1px solid #a7a2a2;
  border-radius: 3px;
  padding: 4px 5px;
  outline: none; }

@media (max-width: 499px) {
  .Section__DesktopOnly__3zTKQ {
    display: none !important; } }

.Section__Section__3l5s3 {
  padding: 0 15px !important; }

.Section__Section__Grouper__LVCf_ {
  display: flex;
  flex-flow: column;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px; }

.Section__Section__Title__1W2d9 {
  color: #fff;
  background: #ec407a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(30deg, #ec407a, #d81b60);
  background: linear-gradient(60deg, #ec407a, #d81b60);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  padding: 15px;
  margin: 0 15px;
  margin-top: -15px;
  border-radius: 3px;
  text-align: start;
  display: inline-block; }

.Section__Section__Title__1W2d9 h4 {
  font-weight: 300; }

.Section__Section__Content__Qhg3I {
  flex-basis: 100%;
  padding: 30px;
  align-content: center; }

@media (min-width: 500px) {
  .Section__Section__Content__Qhg3I {
    padding: 30px; } }

@media (max-width: 499px) {
  .AddDialog__DesktopOnly__20xHG {
    display: none !important; } }

@media (min-width: 800px) {
  .AddDialog__AddDialog__-rw3c {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddDialog__AddDialog__-rw3c {
    min-width: 350px; } }

@media (max-width: 499px) {
  .AddCategoryDialog__DesktopOnly__flOwN {
    display: none !important; } }

.AddCategoryDialog__ViewDialog__1Mcqz {
  min-width: calc(100vw - 2 * 48px); }

@media (min-width: 800px) {
  .AddCategoryDialog__ViewDialog__1Mcqz {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .AddCategoryDialog__ViewDialog__1Mcqz {
    min-width: 350px; } }

@media (max-width: 499px) {
  .DialogForm__DesktopOnly__2BYup {
    display: none !important; } }

.DialogForm__Form__3mbN1 {
  margin: 0 auto;
  font-size: 0.95em; }

@media (max-width: 499px) {
  .LabelWithBtn__DesktopOnly__29INo {
    display: none !important; } }

.LabelWithBtn__InputWithChild__djHyE ul {
  padding: 0 0 0 15px;
  list-style-type: none;
  font-size: 0.8rem; }

.LabelWithBtn__InputWithChild__djHyE ul li {
  margin: 0 0 25px; }

.LabelWithBtn__Header__3mIxE {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 45px; }

.LabelWithBtn__Btn__2JVtq {
  cursor: pointer;
  background: red;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.LabelWithBtn__Btn__2JVtq.LabelWithBtn__Add__cRmkT {
  background: #0084f4; }

.LabelWithBtn__Btn__2JVtq.LabelWithBtn__Remove__3DT25 {
  background: #ff647c; }

.LabelWithBtn__Btn__2JVtq svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 25; }

@media (min-width: 500px) {
  .LabelWithBtn__InputWithChild__djHyE ul {
    padding: 0 0 0 15px; }
  .LabelWithBtn__InputWithChild__djHyE ul li {
    margin: 0; }
  .LabelWithBtn__Header__3mIxE {
    margin-top: 0; } }

.MinimizeableInput__Header__28fph {
  padding: 4px 10px;
  margin: 5px 0;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  background: #1988f8;
  font-weight: bold;
  justify-content: space-between; }

.MinimizeableInput__Content__Qk_9X {
  padding: 0 0 0 15px;
  font-size: 0.8rem;
  overflow: hidden;
  max-height: 1000vh;
  -webkit-transition: max-height 1s ease-in;
  transition: max-height 1s ease-in; }

.MinimizeableInput__Hide__17t8L .MinimizeableInput__Content__Qk_9X {
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
  -webkit-transition-delay: -0.4s;
          transition-delay: -0.4s; }

@media (max-width: 499px) {
  .Content__DesktopOnly__3SCkc {
    display: none !important; } }

.Content__Content__2HCRj {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.Content__SubContent__U7Koi {
  display: flex;
  flex-direction: column;
  flex-basis: 100%; }

.Content__Header__26tKq {
  margin: -30px -30px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex; }

.Content__Actions__3EkHT {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -30px -30px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  justify-content: flex-end; }

.Content__Description__38d97 {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -30px;
  margin-top: 16px;
  padding: 5px 30px; }

@media (min-width: 800px) {
  .Content__Content__2HCRj {
    flex-direction: row; }
  .Content__SubContent__U7Koi {
    flex-basis: 40%; } }

.CustomDatePicker__CustomDatePicker__2948y {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  width: 100px; }

.CustomDatePicker__CustomDatePickerWrapper__wsmkN {
  display: flex;
  flex-basis: 50%; }

@media (max-width: 499px) {
  .ViewDialog__DesktopOnly__2XE2R {
    display: none !important; } }

@media (min-width: 800px) {
  .ViewDialog__ViewDialog__1RYB8 {
    min-width: 600px; } }

@media (min-width: 500px) and (max-width: 800px) {
  .ViewDialog__ViewDialog__1RYB8 {
    min-width: 350px; } }

@media (max-width: 499px) {
  .AddReport__DesktopOnly__LZbn_ {
    display: none !important; } }

.AddReport__AddReport__1K1uQ {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.AddReport__AddReport__1K1uQ .AddReport__CustomDatePicker__3nmCM {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100px; }

.AddReport__AddReport__1K1uQ .AddReport__CustomDatePickerWrapper__v-j1C {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@media (max-width: 499px) {
  .ViewReport__DesktopOnly__2HC40 {
    display: none !important; } }

.ViewReport__AddReport__w-FCX {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }

.ViewReport__AddReport__w-FCX .ViewReport__CustomDatePicker__3FViT {
  background: #fff;
  border: 1px solid #a7a2a2;
  outline: none;
  border-radius: 3px;
  padding: 4px 12px;
  height: 36px;
  width: 100px; }

.ViewReport__AddReport__w-FCX .ViewReport__CustomDatePickerWrapper__3kSOw {
  display: flex;
  align-items: center;
  justify-content: space-between; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh; }

body div[id="root"] {
  position: relative;
  height: 100% !important;
  width: 100% !important; }

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

