@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Settings {
  padding-bottom: 4.5em;
  display: inline-block;
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%; }
  .Settings .Section {
    padding: 0 15px !important; }
  .Settings .Wrapper {
    display: flex;
    flex-flow: column;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 6px; }
  .Settings .Title {
    color: #fff;
    background: linear-gradient(60deg, #4266b7, #2c24aa);
    box-shadow: 0 12px 20px -10px rgba(54, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(39, 66, 176, 0.2);
    padding: 15px;
    margin: 0 15px;
    margin-top: -15px;
    border-radius: 3px;
    text-align: start;
    display: inline-block; }
    .Settings .Title h4 {
      font-weight: 300; }
  .Settings .Content {
    flex-basis: 100%;
    padding: 30px;
    align-content: center; }
  .Settings .Form {
    margin: 0 auto;
    font-size: 0.95em; }
  .Settings .InputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
    justify-content: space-between; }
    .Settings .InputWrapper .Input {
      height: 36px;
      background: #fff;
      border: 1px solid #cccccc;
      outline: none;
      border-radius: 3px;
      padding: 4px 12px;
      width: 100%;
      flex-basis: 100%;
      text-overflow: ellipsis;
      font-size: 0.8em; }
      .Settings .InputWrapper .Input:disabled {
        background: #f2f2f2; }
    .Settings .InputWrapper .InputWithBtn {
      display: flex;
      width: 100%;
      height: 100%; }
      .Settings .InputWrapper .InputWithBtn .Input {
        flex: 1; }
    .Settings .InputWrapper .InputBtn {
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
      background: #1988f8;
      color: #fff;
      outline: none;
      border-radius: 3px;
      padding: 4px 12px;
      height: 36px;
      width: 100%;
      text-overflow: ellipsis;
      font-size: 0.8em;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center; }
    .Settings .InputWrapper .EditBtn {
      background-color: #4caf50;
      box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 3px;
      display: flex;
      height: 36px;
      width: 36px;
      justify-content: center;
      align-items: center;
      margin-left: 5px; }
      .Settings .InputWrapper .EditBtn svg {
        fill: #fff; }
  @media (min-width: 500px) {
    .Settings .Form {
      max-width: 560px; }
    .Settings .InputWrapper {
      align-items: center;
      display: flex;
      margin: 0 40px 15px;
      flex-direction: row;
      height: 36px; }
      .Settings .InputWrapper .Input {
        height: 100%;
        width: auto;
        flex-basis: 50%;
        text-overflow: ellipsis;
        font-size: 0.8em; }
        .Settings .InputWrapper .Input:disabled {
          background: #f2f2f2; }
      .Settings .InputWrapper .InputWithBtn {
        display: flex;
        flex-basis: 50%;
        height: 100%; }
        .Settings .InputWrapper .InputWithBtn .Input {
          flex: 1; }
      .Settings .InputWrapper .InputBtn {
        flex-basis: 50%; }
    .Settings .Content {
      padding: 30px; } }
