@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Content {
  height: inherit;
  min-height: inherit; }

@media (min-width: 500px) {
  .ContentWithToolbar {
    margin-left: calc(22px + 24px * 2); } }
