pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */ }

.ReportFormPrintWrapper {
  page-break-inside: avoid; }

.ReportFormPrint {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 4.5px 20.5px;
  font-size: 0.9em; }

.ReportHeaderLayout {
  padding: 4.5px 20.5px;
  margin: 0 -20.5px;
  border-bottom: 1px solid black; }

.WrapperAfterHeader {
  margin: 16px 0; }

.DetailLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.Report__FieldWrapper,
.Customer__FieldWrapper {
  padding: 0 0 0 15px;
  font-size: 0.9rem; }

.Report__FieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.5px; }

.Customer__FieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 0.8rem;
  margin-bottom: 7.5px; }

.ReportDetailLayout, .ProductLayout,
.DescriptionLayout,
.CustomerDetailLayout {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  font-weight: 500; }

.ProductLayout,
.DescriptionLayout {
  flex-basis: 100%; }

.ProductWrapper {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #a7a2a2;
  border-radius: 3px;
  padding: 10px 14px;
  margin-bottom: 7.5px; }

.Product {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.ProductWrapper:last-child {
  margin-bottom: 20.5px; }

.Product__OriOrChange {
  flex-basis: 45%; }

.Product__FieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 7.5px; }

.DescriptionLayout {
  margin: 0 -20.5px;
  padding: 0 20.5px;
  padding-top: 5px;
  border-top: 1px solid black; }

.DescriptionTextArea {
  width: 100%;
  height: auto;
  min-height: 35px;
  flex-basis: 50%;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  background: #fff;
  border: 1px solid #a7a2a2;
  border-radius: 3px;
  padding: 4px 5px;
  outline: none; }
