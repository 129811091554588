@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.Form {
  margin: 0 auto;
  font-size: 0.95em; }

@media (min-width: 500px) {
  .Form {
    max-width: 560px; } }
