@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.NavigationItem {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer; }

.Arrow {
  height: 22px;
  width: 22px; }

.Username {
  font-weight: 300;
  font-size: 0.9rem; }

.NavigationItem a {
  height: 100%;
  width: 100%;
  color: #8f5c2c;
  text-decoration: none;
  display: flex;
  box-sizing: border-box;
  padding: 16px 10px; }

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40a4c8; }

@media (min-width: 500px) {
  .NavigationItem {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0; }
  .NavigationItem a {
    color: white;
    padding: 16px 10px; }
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    color: #333333; } }
