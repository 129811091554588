@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.InputWithChild ul {
  padding: 0 0 0 15px;
  list-style-type: none;
  font-size: 0.8rem; }
  .InputWithChild ul li {
    margin: 0 0 25px; }

.Header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 45px; }

.Btn {
  cursor: pointer;
  background: red;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }
  .Btn.Add {
    background: #0084f4; }
  .Btn.Remove {
    background: #ff647c; }
  .Btn svg {
    fill: #fff;
    stroke: #fff;
    stroke-width: 25; }

@media (min-width: 500px) {
  .InputWithChild ul {
    padding: 0 0 0 15px; }
    .InputWithChild ul li {
      margin: 0; }
  .Header {
    margin-top: 0; } }
