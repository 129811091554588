@media (max-width: 499px) {
  .DesktopOnly {
    display: none !important; } }

.InputNoMarginLeftRight {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.Input--medium {
  height: 24px !important;
  margin: 0 0 25px !important; }

.Input {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  align-items: flex-start;
  justify-content: space-between; }
  .Input .Input__Btn {
    cursor: pointer; }
  .Input .Input__Field, .Input .Input__TextArea {
    height: 36px;
    background: #fff;
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 3px;
    padding: 4px 12px;
    width: 100%;
    flex-basis: 100%;
    text-overflow: ellipsis;
    font-size: 0.8rem; }
    .Input .Input__Field:disabled, .Input .Input__TextArea:disabled {
      background: #f2f2f2;
      border-color: #f2f2f2;
      color: #999999; }
  .Input .Input__TextArea {
    padding: 4px 5px;
    height: 100%; }
  .Input .Input__FieldWithBtn {
    display: flex;
    width: 100%;
    height: 100%; }
    .Input .Input__FieldWithBtn .Input {
      flex: 1; }
  .Input .Input__BigBtn {
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
    background: #1988f8;
    color: #fff;
    outline: none;
    border-radius: 3px;
    padding: 4px 12px;
    height: 36px;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Input .Input__SmallBtn {
    background-color: #4caf50;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    display: flex;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    margin-left: 5px; }
    .Input .Input__SmallBtn svg {
      fill: #fff;
      stroke: #fff;
      stroke-width: 25; }

@media (min-width: 500px) {
  .Input--medium {
    height: 24px !important;
    margin-bottom: 7.5px !important; }
  .Input {
    align-items: center;
    display: flex;
    margin: 0 40px 5px;
    flex-direction: row;
    height: 36px; }
    .Input .Input__Field, .Input .Input__TextArea {
      width: 100%;
      height: 100%;
      flex-basis: 50%;
      text-overflow: ellipsis;
      font-size: 0.8rem; }
      .Input .Input__Field:disabled, .Input .Input__TextArea:disabled {
        background: #f2f2f2;
        border-color: #f2f2f2;
        color: #999999; }
    .Input .Input__FieldWithBtn {
      display: flex;
      flex-basis: 50%;
      height: 100%;
      justify-content: flex-end; }
      .Input .Input__FieldWithBtn .Input__Field, .Input .Input__FieldWithBtn .Input__TextArea {
        flex: 1; }
    .Input .Input__BigBtn {
      flex-basis: 50%; } }
