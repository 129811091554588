.DrawerToggle {
  width: 22px;
  height: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1px 0;
  margin: 0 12px; }

.DrawerToggle div {
  width: 100%;
  height: 2px;
  background-color: #c9c9c9; }
